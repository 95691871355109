@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: "Marcellus", serif;
  letter-spacing: 2px;
  font-optical-sizing: auto;
  font-style: normal;
  color: var(--front-color);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* --back-color: #e2b76e;  */
  --back-color: #f0eadc; 
  /* --back-color: transparent;  */
  --front-color: #300c01;

  background-image: url("../public/background.jpg");
  background-position: left;
  background-size: 100% 100%;
}

@media (max-width:800px) { 
  body{
    background-image: none; 
    background-color: #f0eadc;
  }
}

.bg-pr{
  /* background-color: var(--back-color); */

}

.bg-no {
  background-color: transparent;
}

.bg-sl{
 background-color: #f3efe7;
 
  /* background-color: transparent; */
}

.radius-s{
  border-radius: 10px;
}

.carousel-container {
  z-index: 0;
  display: flex;
  height: 70vh;
  /* width: 90vw; */
  aspect-ratio: 95/71;
  max-width: 100%;
  top: 5rem;
  position: sticky;
  /* bottom: 50%; */
  /* transform: translateY(50%); */
}

.carousel {
  height: 70vh;
  aspect-ratio: 95/71;
  width: auto;
  max-width: 100%;
  overflow:hidden;
  flex: 1;
}

.image {
  height: 70vh;
  max-width: 100%;
  object-fit: contain;
}

.video-container{
  z-index: 0;
  display: flex;
  height: 80vh;
  width: 90vw;
  position: fixed;
  bottom: 50%;
  transform: translateY(50%);
}
.video {
  height: 80vh;
  width: 90vh;
  overflow:hidden;
  flex: 1;
}

.content{
  z-index: 1;
  min-width: 60vw;
}

.part{
  display: flex;
  min-height: 30vh;
  width: 100%;
  background-color: var(--back-color);
  padding: 4rem 10rem;
}

.blank{
  height: 30vh;
  background-color: transparent;
}

.distance, .distance>div{
  display: flex;
  flex-direction: column;
}

.distance>span{
  font-size: 2rem;
  margin-bottom: 2rem;
}

.distance>div{
  gap: 8px;
}

.calendar-content{
  height: calc(100vh - 5rem);
  overflow-y: scroll;
  scrollbar-width: none;
}

.side-bar{
  flex: 1;
}

.flex-adapt{
  display: flex;
  flex-direction: row;
}

.dynamic-padding-x{
  padding-left: 5vw;
  padding-right: 5vw;
}

.header-button{
  display: flex;
  justify-content: left;
  align-items: center;
}

.locations-4{
  width: 60%;
}

.locations-4 > div {
  flex-basis: 30%;
}

.blank:first-child {
  height: 70vh;
}

.locations-6 > div {
  flex-basis: 20%;
}

@media (min-width:1100px) {
  .main > .blank{
    height: 50vh;
  }
  .calendar-content{
    padding-right: calc(20% + 5rem)
  }
}

@media (max-width:1100px) {
  .side-bar{
    display: none;
  }
  .flex-adapt{
    flex-direction: column;
    align-items: center;
  }
  .distance{
    margin-bottom: 3rem;
  }
  .locations-4{
    width: 90%;
  }
  .locations-4 > div{
    flex-basis: 100%;
  }
  .locations-6 > div{
    flex-basis: 100%;
  }
  .part{
    padding: 4rem 3rem;
  }
  .dynamic-padding-x{
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .blank:first-child {
    height: 40vh;
  }
  .carousel-container {
    /* height: 60vh;
    bottom: 50%;
    transform: translateY(50%); */
  }
  .video-container{
    top: 7rem;
    width: 100%;
    height: auto;
    z-index: 0;
    display: flex;
    position: fixed;
    transform: none;
  }
  .video {
    width: 80%;
    height: unset;
    overflow:hidden;
    flex: 1;
  }
  .main > p, .main > div > p {
    font-size: 1rem;
  }
}

.part.main{
  display: flex;
  flex-direction: column;
}

.part.main > p:first-child{
  font-size: 2.3rem; 
}

.animated-font-size {
  font-size: 1.5rem;
}